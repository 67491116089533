.main-container {
  border-radius: 16px;
  outline: 1.5px solid #535353;
  background: linear-gradient(269deg, #1d1d1d 0%, #262626 100%);
  transition: outline 150ms linear;

  color: white;
}
.main-container:hover {
  border-radius: 16px;
  outline: 4px solid #535353;
  background: linear-gradient(269deg, #1d1d1d 0%, #262626 100%);

  color: white;
}
